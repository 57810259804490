import { useQuery } from "@tanstack/react-query";
import { getPartnerUserInvite } from "../services/partner.service";
import { useMemo } from "react";

function usePartnerUserInvite({
  email,
  refetchOnWindowFocus = true,
}: {
  email?: string;
  refetchOnWindowFocus?: boolean;
}) {
  const queryKey = useMemo(() => {
    return ["/api/v1/partner-users-invite/get-invite", email];
  }, [email]);

  const query = useQuery(
    queryKey,
    () => getPartnerUserInvite({ email: email ?? "" }),
    {
      enabled: !!email,
      refetchOnWindowFocus,
    }
  );
  return { query, queryKey };
}

export default usePartnerUserInvite;
