import { OTPLESS_APP_ID } from "../constants";

export const initOTPless = (
  callback: (user: { identities: { identityValue: string }[] }) => void
) => {
  const otplessInit = Reflect.get(window, "otplessInit");

  const loadScript = () => {
    const isScriptLoaded = document.getElementById("otpless-sdk");
    if (isScriptLoaded) return;

    const script = document.createElement("script");
    script.id = "otpless-sdk";
    script.type = "text/javascript";
    script.src = "https://otpless.com/v3/auth.js";
    script.setAttribute("data-appid", OTPLESS_APP_ID);
    document.head.appendChild(script);
  };
  if (otplessInit) {
    otplessInit();
  } else {
    loadScript();
  }

  Reflect.set(window, "otpless", callback);
};
