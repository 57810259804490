import { FC, useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import useUserSignUpLink, { PartnerUserURLData } from "./useUserSignUpLink";
import DarDocLogo from "../../assets/images/logo.jpg";
import SignUpForm from "./SignUpForm";
import OTPForm from "./OTPForm";
import {
  authService,
  createPartnerUser,
  getFirebaseCustomTokenForPartnerDashboard,
} from "../../services";
import { useMutation } from "@tanstack/react-query";
import { initOTPless } from "../../utils/otplessInit";
import useHandleUserAuthSuccess from "../../hooks/useHandleUserAuthSuccess";
import { signInWithCustomToken } from "firebase/auth";
import useFireAuth from "../../hooks/useFireAuth";
import { showMessage } from "../../components/common/notification";
import decrypt from "../../services/decrypt";
import { Link, useHistory, useParams } from "react-router-dom";
import usePartnerUserInvite from "../../hooks/usePartnerUserInvite";
import usePartnerDetails from "../../hooks/usePartnerDetails";
import LoadingSpinner from "../../components/LoadingSpinner";

const UserSignUpLink: FC<UserSignUpLinkProps> = () => {
  // const {
  //   checkUniqueUserMutation,
  //   sendOTPLoading,
  //   sendFireAuthOTPConfirmationResult,
  //   resendOTPLoading,
  //   formValues,
  //   confirmFirebaseOTPLoading,
  //   verifyWhatsappOTPMutation,
  //   sendWhatsappOTPMutation,
  //   // firebaseCustomTokenMutation,
  //   // loginPartnerUser,
  //   handleRequestCallClicked,
  //   handleFormSubmit,
  //   handleSignupOTPEntered,
  //   handleResendOTPClicked,
  // } = useUserSignUpLink({ mode: "SIGNUP" });
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const params = useParams<{ userData: string }>();
  const userLinkData = useMemo<PartnerUserURLData | null>(() => {
    try {
      return decrypt(decodeURIComponent(params.userData));
    } catch (e) {
      return null;
    }
  }, [params.userData]);
  const { query: inviteQuery } = usePartnerUserInvite({
    email: userLinkData?.email,
    refetchOnWindowFocus: false,
  });
  const history = useHistory();
  const { query: partnerQuery } = usePartnerDetails({
    partnerUUID: userLinkData?.partnerUUID,
  });
  const { fireAuth } = useFireAuth();
  const loginPartnerUser = useMutation({
    mutationFn: authService.userLoginWithPhoneNumber,
  });
  const { handleUserAuthSuccess } = useHandleUserAuthSuccess();
  const firebaseCustomTokenMutation = useMutation({
    mutationFn: getFirebaseCustomTokenForPartnerDashboard,
  });
  const createUserMutation = useMutation({ mutationFn: createPartnerUser });
  const isLoadingPage = loginPartnerUser.isLoading ||
  createUserMutation.isLoading ||
  partnerQuery.isLoading ||
  inviteQuery.isLoading
  useEffect(() => {
    const loginUser = async (otplessUser: {
      identities: { identityValue: string }[];
    }) => {
      if (!fireAuth) {
        showMessage("error", "Something went wrong!");
        return;
      }
      setIsLoading(true)
      const fullPhoneNumber = `+${otplessUser?.identities?.[0].identityValue}`;
      const tokenRes = await firebaseCustomTokenMutation.mutateAsync({});
      const customTokenCredentials = await signInWithCustomToken(
        fireAuth,
        tokenRes?.token ?? ""
      );

      const userPayload = {
        email: userLinkData?.email ?? "",
        firebaseUID: customTokenCredentials.user.uid,
        name: partnerQuery.data?.partner?.name ?? " ",
        partnerUUID: userLinkData?.partnerUUID ?? "",
        phoneNumber: fullPhoneNumber,
        role: userLinkData?.role ?? "",
      };
      const createUserResponse = await createUserMutation.mutateAsync(
        userPayload
      );
      if (!createUserResponse?.user?.phoneNumber) {
        setIsLoading(false)
        return showMessage("error", "Something went wrong, please try again!");
      }
      const loginRes = await loginPartnerUser.mutateAsync({
        phoneNumber: fullPhoneNumber,
      });
      localStorage.setItem("newAuthLogic", "true");
      handleUserAuthSuccess(loginRes.data.settings);
      if (userLinkData?.role === "SUPER_ADMIN") {
        history.replace("/settings");
      } else {
        history.replace("/");
      }
    };
    !isLoadingPage && initOTPless(loginUser);
  }, [
    handleUserAuthSuccess,
    loginPartnerUser,
    userLinkData,
    fireAuth,
    createUserMutation,
    firebaseCustomTokenMutation,
    partnerQuery.data?.partner?.name,
    history,
    isLoadingPage
  ]);
  console.log()
  return (
    <>
      {isLoadingPage || isLoading ? (
        <LoadingSpinner styles={{ backgroundColor: "white" }} spinning={true}>
          {null}
        </LoadingSpinner>
      ) : null}
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        {inviteQuery.data?.success && !inviteQuery.data?.invite?._id ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
              alignItems: "center",
            }}
          >
            <h1 style={{ color: "red" }}>Invalid Signup Link</h1>
            <Link to={"/login"}>Sign In</Link>
          </div>
        ) : (
          <div id="otpless-login-page"></div>
        )}
        {/* <Grid
        id="login-form-container"
        container={true}
        spacing={2}
        sx={{ alignItems: "center", justifyContent: "center", marginBottom: "200px" }}
      >
        <div id="login-captcha" />
        <Grid xs={12} sm={12} md={6} lg={4} item={true}>
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <img src={DarDocLogo} alt="DarDoc" style={{ width: "120px" }} />
          </div>
          <h3 style={{ textAlign: "center" }}>Sign Up</h3>
          {sendFireAuthOTPConfirmationResult ? (
            <OTPForm
              mobileNumber={formValues?.mobileNumber ?? ""}
              resendOTPLoading={resendOTPLoading}
              submitLoading={
                confirmFirebaseOTPLoading ||
                checkUniqueUserMutation.isLoading ||
                verifyWhatsappOTPMutation.isLoading ||
                firebaseCustomTokenMutation.isLoading ||
                loginPartnerUser.isLoading
              }
              requestCallLoading={sendWhatsappOTPMutation.isLoading}
              handleCallRequestClicked={handleRequestCallClicked}
              handleFormSubmit={handleSignupOTPEntered}
              handleResendOTPClicked={handleResendOTPClicked}
            />
          ) : (
            <SignUpForm submitLoading={checkUniqueUserMutation.isLoading || sendOTPLoading} handleFormSubmit={handleFormSubmit} />
          )}
        </Grid>
      </Grid> */}
      </div>
    </>
  );
};

interface UserSignUpLinkProps {}

export default UserSignUpLink;
