import { FC } from "react";
import { Box } from "@mui/material";
import NurseVerificationDocPDF from "../../components/NurseVerificationDocPDF";
import NurseVerificationDocImage from "./NurseVerificationDocImage";

const ViewPrescriptionButton: FC<ViewPrescriptionButtonProps> = ({ prescriptionURL }) => {
  if (!prescriptionURL) return null;
  return (
    <>
      {prescriptionURL.indexOf(".pdf") > -1 ? (
        <Box width={"fit-content"} marginTop={3} marginBottom={3}>
          <NurseVerificationDocPDF
            docURL={prescriptionURL}
            showViewButton={false}
            downloadButtonText="Download prescription"
            viewButtonText="View prescription"
          />
        </Box>
      ) : (
        <Box
          width={"200px"}
          height={"200px"}
          marginTop={3}
          marginBottom={3}
          border={`1px solid #25bcbd`}
          borderRadius={"8px"}
          padding={1}
        >
          <NurseVerificationDocImage
            imageURL={prescriptionURL}
            alt={"prescription"}
            alwaysHoverMode={true}
            viewButtonText="View prescription"
          />
        </Box>
      )}
    </>
  );
};

interface ViewPrescriptionButtonProps {
  prescriptionURL: string;
}

export default ViewPrescriptionButton;
